import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin-left: -6%;
    position: relative;
`



export const Map = styled.img`
    width: 100%;
    height: 100%;
`

export const Fill = styled(Map)`
  position: absolute;
  left: 0;
  top: -0.2%;
  opacity: 0;
  transition: all 2.5s cubic-bezier(0.78, 0, 0.235, 1) 800ms;
  ${({ visible }) => visible
    && css`
        opacity: 1;
      `};
`

