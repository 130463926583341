import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import AvatarMessage from 'src/components/desktop/avatarMessage/avatarMessage';
import { ColumnContent, Wrapper, LeftContent, CenterContent, RightContent } from './styles';

const ThreeColsContent = ({
  body, image, dark, audio, audioImage, audioSize, avatarImage, style,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.8,
  });
  return (
    <ColumnContent dark={dark} ref={ref} visible={inView} style={style}>
      <MDXProvider components={{
        Wrapper,
        Hyperlink,
        AvatarMessage,
        AudioPlayer,
        p: (props) => <p {...props} tabIndex="0" />,
      }}
      >
        <LeftContent visible={inView}>
          <MDXRenderer image={image} visible={inView} audio={audio} avatarImage={avatarImage} audioImage={audioImage} size={audioSize}>
            {body}
          </MDXRenderer>
        </LeftContent>
        <CenterContent visible={inView}>
          <MDXRenderer image={image} visible={inView} audio={audio} avatarImage={avatarImage} audioImage={audioImage} size={audioSize}>
            {body}
          </MDXRenderer>
        </CenterContent>
        <RightContent visible={inView}>
          <MDXRenderer image={image} visible={inView} audio={audio} avatarImage={avatarImage} audioImage={audioImage} size={audioSize}>
            {body}
          </MDXRenderer>
        </RightContent>
      </MDXProvider>
    </ColumnContent >
  );
};

export default ThreeColsContent;
