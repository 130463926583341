import React, { createRef } from 'react';
import { Wrapper, Map, Fill } from './styles';
import map from 'src/assets/images/chapter_2/sub_3/desktop/map.svg'
import fill from 'src/assets/images/chapter_2/sub_3/desktop/map-fill.svg'
import { useInView } from 'react-intersection-observer';

const RevolutionAnimation = () => {

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  return (
    <Wrapper ref={ref}>
      <Map src={map} />
      <Fill src={fill} visible={inView} />
    </Wrapper>

  );
};

export default RevolutionAnimation;
