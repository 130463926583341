import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => (
  <BlockContainer height="2450px">
    <BackgroundPhoto fixed={query.bg.childImageSharp.fixed}>
      <ChpaterTitle marginTop="22rem">{title}</ChpaterTitle>
      <TextAnimation
        body={query.Par1.body}
        containerStyle={{ height: '10%', marginBottom: '14rem' }}
      />
      <BackgroundPhoto
        fixed={query.Map1.childImageSharp.fixed}
        enter
        containerStyle={{ height: '250px', zIndex: 1 }}
        alt="slide-photo"
      />
      <TextAnimation
        body={query.Par2.body}
        containerStyle={{ height: '12%' }}
      />
      <BackgroundPhoto
        fixed={query.Map2.childImageSharp.fixed}
        enter
        containerStyle={{ height: '360px', zIndex: 1 }}
        alt="slide-photo"
      />
      <TextAnimation
        body={query.Par3.body}
        containerStyle={{ height: '28%' }}
      />
    </BackgroundPhoto>
  </BlockContainer>
);


export default Slide;
