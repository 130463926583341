import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    top: 70px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 400ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const ColumnContent = styled.div`
  height: 100%;
  margin-right: 2rem;
  padding-left: 13vw;
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
width: ${(props) => (props.longText ? '26rem' : '22rem')};
max-zoom: 40%;
position: relative;
opacity: 0;
animation: ${animationBottom};
`;

export const LeftContent = styled.div`
  flex: 1;
  margin-top: -3px;

  & * {
    display: none;
  }

  .col-left {
    display: block;
  }

& > h2 {
    ${{ ...fonts.headline_1 }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    top: 60px;
    animation: ${animationTop};
  }

  & > h3 {
    ${{ ...fonts.headline_3a }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    position: relative;
    opacity: 0;
    margin-bottom: 2.43rem;
    animation: ${animationTopSecondary};

  }
`;
export const Content = styled.div`
  flex: 1;
  & > p, & > div > p {
    ${{ ...fonts.paragraf }}
    ${(props) => (props.dark ? { ...colors.dark } : { ...colors.light })}
    max-width: 398px;
    display: inline;
  }
  span {
    font-size: 1rem;
    line-height: 1.62rem;
    letter-spacing: 0.03rem;
  }
`;


export const CenterContent = styled(Content)`
  .col-left, .col-right { 
    display: none;
  }
`;
export const RightContent = styled(Content)`
  .col-left, .col-center { 
    display: none;
  }

`;
