import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ThreeColsContent from 'src/components/desktop/threeColsContent/threeColsContent';
import RevolutionAnimation from 'src/components/desktop/revolutionAnimation/revolutionAnimation';
import Marker from 'src/components/desktop/marker/marker';
import { MarkerContainer } from 'src/components/desktop/marker/styles';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
// import { NoiseOverlayLight } from 'src/components/desktop/noiseOverlay/noiseOverlay';
import { SlideLarge } from '../../_styles';

const Slide = ({ query, cityName }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_2/sub_3/desktop/bg-1.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    scenelayer1: file(relativePath: { eq: "chapter_2/sub_3/desktop/bg-2.png"}) {
      childImageSharp {
          fixed(width: 758) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    scenelayer2: file(relativePath: { eq: "chapter_2/sub_3/desktop/bg-3.png"}) {
      childImageSharp {
          fixed(width: 263) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    modal1: file(relativePath: { eq: "chapter_2/sub_3/desktop/111.jpg"}) {
    childImageSharp {
      fixed(width: 517, height: 745)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
      }
    }
    modal2: file(relativePath: { eq: "chapter_2/sub_3/desktop/2.jpg"}) {
      childImageSharp {
        fixed(width: 506, height: 762)  {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
        }
      }
    modal3: file(relativePath: { eq: "chapter_2/sub_3/desktop/3.jpg"}) {
      childImageSharp {
        fixed(width: 506, height: 762)  {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
        }
      }
    modal4: file(relativePath: { eq: "chapter_2/sub_3/desktop/4.jpg"}) {
      childImageSharp {
        fixed(width: 506, height: 762)  {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
        }
      }
  }
`);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          background={{ image: assetQuery.mainscene.childImageSharp.fixed }}
        />
        {/* <NoiseOverlayLight /> */}

        <Mordo
          scenelayer={{ size: '40%', position: 'bottom left' }}
          scene={1}
          move={{ x: 10, y: 20 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fixed }}
        />
        <Mordo
          scenelayer={{ size: '14%', position: 'bottom left', fromBottom: '5%' }}
          scene={2}
          move={{ x: -5, y: -20 }}
          background={{ image: assetQuery.scenelayer2.childImageSharp.fixed }}
        />
        <Mordo
          scene={3}
          move={{ x: -10, y: -5 }}
          background={{ color: 'transparent' }}
        >
          <MarkerContainer positionLeft="50%" positionTop="6%" customWidth="1100px" customWidth1600="1240px">
            <Marker
              positionRight="80%"
              positionTop="21%"
              body={query.modal4.edges[0].node.body}
              text={query.modal4.edges[0].node.frontmatter.name}
              image={assetQuery.modal4.childImageSharp.fixed}
            />
            <Marker
              positionRight="87%"
              positionTop="35%"
              body={query.modal2.edges[0].node.body}
              text={query.modal2.edges[0].node.frontmatter.name}
              image={assetQuery.modal2.childImageSharp.fixed}
            />
            <Marker
              positionRight="82.7%"
              positionTop="40.3%"
              body={query.modal3.edges[0].node.body}
              text={query.modal3.edges[0].node.frontmatter.name}
              image={assetQuery.modal3.childImageSharp.fixed}
            />
            <Marker positionRight="68.5%" positionTop="30.3%" text={cityName} nodot noclick />
            <RevolutionAnimation />
          </MarkerContainer>
        </Mordo>
        <Mordo
          scene={4}
          move={{ x: -5, y: -10 }}
          flexi={{ alignSelf: 'flex-end' }}
          customStyle={{ height: '46%' }}
        >
          <ThreeColsContent
            body={query.leftColumn.body}
            image={assetQuery}
          />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>
  );
};

export default Slide;
